import { Dropdown, Typography } from "cakemail-ui-components-v2"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { CustomerContext, PricingPageContext } from "../../contexts"
import './styles.scss'


export default function ContactSelector() {
    const customer = useContext(CustomerContext)
    const pricingCards = useContext(PricingPageContext)
    const { t: translate } = useTranslation()
    const [searchParams] = useSearchParams()

    /**
     * Handles the change event of the selected contact tier.
     *
     * @param event - The event object.
     * @param newValue - The new value of the selected contact tier.
     */
    function changeSelectedContactTier(event: any, newValue: any) {
        if (pricingCards && newValue) pricingCards.setSelectecContactTier(Number(newValue.props.value))
    }

    function displayHeadline() {
        if (customer?.authenticatedMode) {
            return <Typography sx={{ textTransform: "none !important" }} variant="h3">{translate('plan-contact-selector')} &nbsp;</Typography>
        }
        if (searchParams.get("landingPage") === "true") {
            return <Typography className="mobile-size" sx={{ textTransform: "none !important" }} variant="h3"> {translate("plan-contact-selector-landing_page")} </Typography>
        }
        return <Typography className="mobile-size" sx={{ textTransform: "none !important" }} variant="h1">{translate('plan-contact-selector')}</Typography>

    }


    return <>
        {pricingCards?.contactTiers &&
            <div className={customer?.authenticatedMode ? "contact-selector" : "contact-selector-mobile"}>
                {displayHeadline()}
                <Dropdown
                    className="contact-dropdown"
                    disabled={(customer?.authenticatedMode && customer.updating) || pricingCards.disabledButtons}
                    value={pricingCards.selectedContactTier ? pricingCards.selectedContactTier.toString() : undefined}
                    onChange={changeSelectedContactTier}
                    options={pricingCards.contactTiers?.map((item) => ({
                        text: item.toLocaleString() + " " + translate('plan-contacts'),
                        value: item.toString(),
                    }))}
                    size="small"
                    variant="outlined"
                />
            </div >
        }
    </>
}